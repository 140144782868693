import React, { useEffect, useRef, useState } from "react";
import Select from "react-select";
import Banner from "../../../images/step2.png";
import { connect } from "react-redux";
import AutoComplete from "../../maps/Autocomplete";
import { useGoogleLogin } from "react-google-login";

const Step2 = ({
  setStep,
  categoryList,
  formData,
  setFormData,
  onChange,
  calenderEmail,
  setCalendarEmail,
}) => {
  const closeRef = useRef(null);
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [showCalandly, setShowCalandly] = useState(false);
  const [showJaneApp, setShowJaneApp] = useState(false);
  const [isLocationSelected, setIsLocationSelected] = useState(false);

  const [isSynced, setIsSynced] = useState(false);
  const categoryRef = useRef(null);
  const [isSelected, setIsSelected] = useState({
    category: false,
    subcategory: false,
    location: false,
  });

  const onCategoryChange = (values) => {
    let dropdownfield = [{ label: "Select Subcategory", value: "" }];
    categoryList?.map((element) => {
      if (element.category_name === values) {
        element?.sub_category_name.map((value) => {
          dropdownfield.push({
            label: value.text,
            value: value.text,
          });
        });
      }
    });
    setSubCategories(dropdownfield);
    setFormData({
      ...formData,
      category: values,
      subcategory: "",
    });
  };

  let subcategoryValue, filterValue;
  filterValue = categories.filter((i) => i.value === formData?.category);
  if (formData.category) {
    subcategoryValue = subCategories?.filter(
      (i) => i.value === formData?.subcategory
    );
  }

  useEffect(() => {
    let CategoryList = [{ label: "Select Category", value: "" }];
    categoryList?.map((element) => {
      CategoryList.push({
        label: element.category_name,
        value: element.category_name,
      });
    });
    setCategories(CategoryList);
  }, [formData, categoryList]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!isLocationSelected) {
      setIsSelected((prev) => ({
        ...prev,
        location: true,
      }));
      return;
    }
    setStep(3);
  };

  const addPlace = (place) => {
    const addressArray = {};
    place.address_components.length > 0 &&
      place.address_components.forEach((add) => {
        if (add.types[0] === "street_number" || add.types[0] === "route") {
          addressArray["street_address"] =
            (addressArray["street_address"] || "") + " " + add.long_name;
        } else if (add.types[0] === "locality") {
          addressArray["city"] = add.long_name;
        } else if (
          add.types[0] === "administrative_area_level_2" &&
          !addressArray["city"]
        ) {
          addressArray["city"] = add.long_name;
        } else if (add.types[0] === "administrative_area_level_1") {
          addressArray["province"] = add.long_name;
        } else if (add.types[0] === "country") {
          addressArray["country"] = add.long_name;
        } else if (add.types[0] === "postal_code_prefix") {
          addressArray["postal"] = add.long_name;
        }
      });
    setFormData({
      ...formData,
      street_address: place.formatted_address,
      city: addressArray.city ?? "",
      country: addressArray.country ?? "",
      province: addressArray.province ?? "",
      postal: addressArray.postal ? addressArray.postal : formData.postal,
      lng: place.geometry.location.lng(),
      lat: place.geometry.location.lat(),
    });
    setIsLocationSelected(true);
  };

  const onSuccess = (res) => {
    setFormData({
      ...formData,
      googleAuthBusiness: res.tokenObj,
      googleRefreshTokenBusiness: "",
      googleUserBusiness: {
        name: res.profileObj.name,
        email: res.profileObj.email,
      },
    });
    setIsSynced(true);
    setCalendarEmail(res?.profileObj?.email);
    closeRef.current.click();
  };

  const onFailure = (res) => {
    console.log("Login failed: res:", res);
  };

  const onDisconnect = () => {
    setFormData({
      ...formData,
      googleAuthBusiness: null,
      googleRefreshTokenBusiness: "",
      googleUserBusiness: null,
    });
    setCalendarEmail(null);
    setIsSynced(false);
  };

  const clientId = process.env.REACT_APP_GOOGLECLIENTID;
  const { signIn } = useGoogleLogin({
    onSuccess,
    onFailure,
    clientId,
    issignedIn: true,
    accessType: "offline",
    scope:
      "profile email https://www.googleapis.com/auth/admin.directory.resource.calendar",
  });

  return (
    <div className="container d-flex flex-column flex-lg-row align-items-center mb-3">
      <div className="flex-grow-1 d-flex flex-column justify-content-center p-3">
        <p className="title mb-4">Tell us about your business</p>
        <div className="business-dialog mx-auto mt-3 w-100" style={{ maxWidth: "620px" }}>
          <div className="d-flex justify-content-between align-items-center w-100">
            <p className="form-title m-0">Business information</p>
            <p className="form-subtitle m-0">2 step left</p>
          </div>
          <div className="progress-bar-outer w-100">
            <span className="progress-baar bg-aqua"></span>
            <span className="progress-baar bg-aqua"></span>
            <span className="progress-baar bg-gray"></span>
            <span className="progress-baar bg-gray"></span>
          </div>
          <form className="form w-100" onSubmit={handleSubmit} data-tut="register_detail">
            <div className="d-flex flex-column buisness-information mt-0 p-1">
              <div className="form-row first-input">
                <div className="col-sm-12">
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Website"
                    name="website"
                    value={formData.website}
                    onChange={onChange}
                  />
                </div>
              </div>
              <div className="form-row first-input">
                <div className="col-sm-12">
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Brief Description / tagline"
                    name="description"
                    value={formData.description}
                    onChange={onChange}
                  />
                </div>
              </div>
              <div className="col-md-12 row p-0 m-0" ref={categoryRef}>
                <div className="col-md-6 px-1">
                  <Select
                    options={categories}
                    className="field-type"
                    placeholder="Category*"
                    styles={{
                      menu: (base) => ({ ...base, zIndex: 99999999 }),
                      control: (baseStyles) => ({
                        ...baseStyles,
                        borderRadius: "8px",
                      }),
                    }}
                    name="category"
                    defaultValue={filterValue && filterValue[0]}
                    value={
                      formData?.category
                        ? {
                            label: formData?.category,
                            value: formData?.category,
                          }
                        : filterValue && filterValue[0]
                    }
                    onChange={(value) => {
                      onCategoryChange(value.value);
                      setIsSelected({
                        ...isSelected,
                        category: false,
                      });
                    }}
                  />
                  {isSelected.category && (
                    <label className="text-danger d-flex">
                      Please select category.
                    </label>
                  )}
                </div>
                {(subCategories?.length > 0 || formData?.subcategory) && (
                  <div className="col-md-6 px-1">
                    <Select
                      options={subCategories}
                      className="field-type"
                      placeholder="Subcategory*"
                      styles={{
                        menu: (base) => ({ ...base, zIndex: 99999999 }),
                        control: (baseStyles) => ({
                          ...baseStyles,
                          borderRadius: "8px",
                        }),
                      }}
                      defaultValue={subcategoryValue && subcategoryValue[0]}
                      value={
                        formData?.subcategory
                          ? {
                              label: formData?.subcategory,
                              value: formData?.subcategory,
                            }
                          : subcategoryValue && subcategoryValue[0]
                      }
                      onChange={(value) => {
                        setFormData({
                          ...formData,
                          subcategory: value.value,
                        });
                        setIsSelected({
                          ...isSelected,
                          subcategory: false,
                        });
                      }}
                    />
                    {isSelected.subcategory && (
                      <label className="text-danger d-flex">
                        Please select subcategory.
                      </label>
                    )}
                  </div>
                )}
              </div>

              <p className="form-title m-0 text-left">Business Address</p>
              {isSelected.location && (
                  <label className="text-danger d-flex">
                  Please select a valid location.
                </label>
                )}
              <div className="form-row m-0 form-group first-input">
              
                <div className="col-sm-6 business-registration" style={{ marginBottom: "64px" }}>
                  <AutoComplete
                    addPlace={addPlace}
                    isFilter={false}
                    defaultAddress={formData?.street_address}
                  />
                  
                </div>
                <div className="col-sm-6 ">
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Street Address2"
                    name="street_address_2"
                    maxLength="50"
                    value={formData.street_address_2}
                    onChange={onChange}
                  />
                </div>
              </div>
              <div className="form-row m-0 form-group first-input">
                <div className="col-sm-6 mb-3 mb-md-0">
                  <input
                    className="form-control"
                    type="text"
                    placeholder="City"
                    name="city"
                    maxLength="50"
                    value={formData.city}
                    onChange={onChange}
                  />
                </div>
                <div className="col-sm-6">
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Province"
                    name="province"
                    maxLength="50"
                    value={formData.province}
                    onChange={onChange}
                  />
                </div>
              </div>
              <div className="form-row m-0 form-group first-input">
                <div className="col-sm-6 mb-3 mb-md-0">
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Company Country"
                    name="country"
                    maxLength="50"
                    value={formData.country}
                    onChange={onChange}
                  />
                </div>
                <div className="col-sm-6">
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Postal Code"
                    name="postal"
                    maxLength="50"
                    value={formData.postal}
                    onChange={onChange}
                  />
                </div>
              </div>

              <p className="form-title m-0 text-left">Sync Calendar</p>
              <div className="d-flex flex-column gap">
                <input
                  type="button"
                  className="btn create-service-btn text-white bg-orange border-0"
                  value="Google Calendar"
                  onClick={signIn}
                  disabled={calenderEmail}
                />
                {(isSynced || calenderEmail) && (
                  <div className="form-control plus-sign calendar-email-box h-auto">
                    <p className="m-0">{calenderEmail}</p>
                    <div className="plusbtnclass">
                      <i
                        className="fa-solid fa-close calender_icon"
                        style={{
                          fontSize: "15px",
                          color: "red",
                          cursor: "pointer",
                        }}
                        onClick={onDisconnect}
                      ></i>
                    </div>
                  </div>
                )}
                <input
                  type="button"
                  className="btn create-service-btn text-white bg-orange border-0"
                  value="Calendly"
                  onClick={() => {
                    setShowCalandly(true);
                    setShowJaneApp(false);
                    setFormData({
                      ...formData,
                      calendartype: "",
                      calendarurl: "",
                    });
                  }}
                />
                {(showCalandly || formData?.calendartype === "calandly") && (
                  <input
                    type="url"
                    className="form-control"
                    placeholder="Enter Calendly URL"
                    value={formData.calendarurl}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        calendartype: "calandly",
                        calendarurl: e.target.value,
                      })
                    }
                  />
                )}
                <input
                  type="button"
                  className="btn create-service-btn text-white bg-orange border-0"
                  value="JaneApp"
                  onClick={() => {
                    setShowJaneApp(true);
                    setShowCalandly(false);
                    setFormData({
                      ...formData,
                      calendartype: "",
                      calendarurl: "",
                    });
                  }}
                />
                {(showJaneApp || formData?.calendartype === "janeapp") && (
                  <input
                    type="url"
                    className="form-control"
                    placeholder="Enter JaneApp URL"
                    value={formData.calendarurl}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        calendartype: "janeapp",
                        calendarurl: e.target.value,
                      })
                    }
                  />
                )}
              </div>

              <div className="d-flex justify-content-between">
                <input
                  type="button"
                  className="btn btn-primary bg-white border-0 text-dark font-weight-bold"
                  style={{ padding: "10px 20px", fontSize: "15px" }}
                  value="Back"
                  onClick={() => setStep(1)}
                />
                <input
                  type="submit"
                  className="btn btn-primary text-dark font-weight-bold"
                  style={{ padding: "10px 20px", fontSize: "15px" }}
                  value="NEXT STEP"
                />
              </div>
            </div>
           
          </form>
        </div>
      </div>
      <div className="d-none d-lg-block" style={{ width: "30%" }}>
        <img src={Banner} className="img-fluid" alt="Banner" />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  categoryList: state.company.category,
});

export default connect(mapStateToProps)(Step2);
