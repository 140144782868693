import React, { useState, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import Select from "react-select";

import {
  addBusinessService,
  editSBusinesservice,
  editUserServices,
  addUserService,
} from "../../actions/company";
import { Helmet } from "react-helmet";
import { options } from "../../utils/constant";
import DetailTooltip from "../layout/DetailTooltip";
import './editUserService.css'

const EditUserService = ({
  addBusinessService,
  editSBusinesservice,
  formfields,
  editUserServices,
  addUserService,
  company,
  categoryList,
}) => {
  const state = useLocation();
  const Company = company.find((c) => c._id == state?.state?.company?._id);

  const data = Company;
  const serviceId = state?.state?.id;
  let selectedService = data.services.find((e) => e._id == serviceId);

  const history = useHistory();

  const [formData, setFormData] = useState({
    _id: null,
    name: "",
    description: "",
    service_duration: 15,
    price: "",
    book_online: false,
    book_site: false,
    book_site_link: "",
    call_to_book: false,
    intakeForm: [],
    company_id: data._id,
  });
  const {
    _id,
    name,
    description,
    service_duration,
    price,
    book_online,
    call_to_book,
    book_site,
    book_site_link,
    intakeForm,
  } = formData;
  const [selectedUserservice, setSelectedUserservice] =
    useState(selectedService);
  const [formError, setFormError] = useState("");
  const [categories, setCategories] = useState([]);
  const clearServices = () => {
    const formsData = {
      book_online: false,
      book_site: false,
      book_site_link: "",
      call_to_book: false,
      description: "",
      intakeForm: [],
      name: "",
      price: "",
      service_duration: 15,
      _id: "",
    };

    setFormData(formsData);

    // clearService();
    setSelectedUserservice(null);
  };

  let fieldinitiaValues = [
    {
      field_category: "",
      field_type: "",
      is_required: false,
      field_name: "",
      field_label: "",
      online_fieldnames: [],
      selected: "",
      option: "",
      formfield: "",
    },
  ];

  const Type = (values, i) => {
    let fields = [...onlineField];
    let field = { ...fields[i] };
    field.field_category = values;
    let dropdownfield = [{ name: "Select One", value: "" }];

    formfields.formfields.map((element, index) => {
      if (element.field_category === values) {
        dropdownfield.push({
          name: element.field_name,
          value: element.field_name,
          is_selected: false,
        });
      }
    });
    field.online_fieldnames = dropdownfield;
    fields[i] = field;
    setOnlineField(fields);
  };
  const onCategoryChange = (values, i) => {
    let fields = [...intakeField];
    let field = { ...fields[i] };
    field.field_category = values.value;
    field.formfield = values.id;

    let dropdownfield = [{ label: "Select One", value: "" }];

    categoryList?.map((element) => {
      if (element.category_name === values.value) {
        element?.sub_category_name.map((value) => {
          dropdownfield.push({
            label: value.text,
            value: value.text,
          });
        });
      }
    });
    field.online_fieldnames = dropdownfield;
    fields[i] = field;
    setIntakeField(fields);
  };
  useEffect(() => {
    setSelectedUserservice(selectedService);
    selectedUserservice && setFormData(selectedUserservice);
    Edit_field(selectedService?.intakeForm);
    Type();
  }, [selectedUserservice, selectedService, formfields]);

  const [onlineField, setOnlineField] = useState(
    intakeForm ? intakeForm : fieldinitiaValues
  );
  const [intakeField, setIntakeField] = useState(
    intakeForm ? intakeForm : fieldinitiaValues
  );

  const [editFields, setEditFields] = useState([]);

  const addField = (e) => {
    setOnlineField([
      ...onlineField,
      {
        field_category: "",
        field_name: "",
        is_required: false,
        field_label: "",
        field_type: "",
        online_fieldnames: [],
        selected: "",
        option: "",
        formfield: "",
      },
    ]);

    setIntakeField([
      ...intakeField,
      {
        field_category: "",
        field_name: "",
        is_required: false,
        field_label: "",
        field_type: "",
        online_fieldnames: [],
        selected: "",
        option: "",
        formfield: "",
      },
    ]);
  };

  const Edit_field = (value) => {
    let fields = [];
    if (value && value.length > 0) {
      fields = [...value];
    }
    fields.length > 0 &&
      fields.map((element, i) => {
        let field = { ...fields[i] };
        let values = field.field_category;
        field.field_category = values;
        let dropdownfield = [{ label: "Select One", value: "" }];
        categoryList.map((element, index) => {
          if (element.category_name === values) {
            element?.sub_category_name.map((value) => {
              dropdownfield.push({
                label: value.text,
                value: value.text,
              });
            });
          }
        });
        field.online_fieldnames = dropdownfield;
        fields[i] = field;
      });
    setEditFields(fields);
  };
  const edit_deleteField = (i) => {
    let formValues = editFields;
    formValues.splice(i, 1);
    setEditFields([...formValues]);
  };

  const onedit_CategoryFieldChange = (e, i) => {
    let fields = [...editFields];
    let field = { ...fields[i] };
    field.selected = "";
    field.field_category = e.target.value;
    fields[i] = field;
    setEditFields(fields);
    edit_Type(e.target.value, i);
  };

  const onedit_isReqChange = (e, i) => {
    let fields = [...editFields];
    let field = { ...fields[i] };

    field.is_required = !field.is_required;
    fields[i] = field;
    setEditFields(fields);
  };

  const oneditFieldTypeChange = (e, i, id) => {
    let fields = [...editFields];
    let field = { ...fields[i] };
    field.field_name = e;
    field.formfield = id;
    if (e === "") {
      field.field_label = "Label";
    }
    fields[i] = field;
    setEditFields(fields);
    edit_onlineflabel(e, i);
  };

  const edit_Type = (values, i) => {
    let fields = [...editFields];
    let field = { ...fields[i] };
    field.field_category = values.value;
    field.formfield = values.id;
    let dropdownfield = [{ label: "Select One", value: "" }];
    categoryList?.map((element) => {
      if (element.category_name === values.label) {
        element?.sub_category_name.map((value) => {
          dropdownfield.push({
            label: value.text,
            value: value.text,
          });
        });
      }
    });

    field.online_fieldnames = dropdownfield;
    fields[i] = field;
    setEditFields(fields);
  };

  const edit_onlineflabel = (value, i) => {
    let fields = [...editFields];
    let field = { ...fields[i] };
    field.field_name = value;
    field.selected = value;
    let options = [];
    formfields.formfields.map((element, index) => {
      if (element.field_name === value) {
        options = element.option;
        field.formfield = element._id;
        field.field_label = element.field_label;
        field.field_type = element.field_type;
      }
      field.option = options;
      fields[i] = field;
      setEditFields(fields);
    });
  };

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const deleteField = (i) => {
    let formValues = onlineField;
    formValues.splice(i, 1);
    setOnlineField([...formValues]);
    setIntakeField([...formValues]);
  };

  const onCategoryFieldChange = (e, i) => {
    let fields = [...onlineField];
    let field = { ...fields[i] };
    field.selected = "";
    field.field_category = e.target.value;
    fields[i] = field;
    setOnlineField(fields);
    Type(e.target.value, i);
  };

  const onlineflabel = (value, i) => {
    let fields = [...onlineField];
    let field = { ...fields[i] };
    field.field_name = value;
    field.selected = value;
    let options = [];
    formfields.formfields.map((element, index) => {
      if (element.field_name === value) {
        field.formfield = element._id;
        options = element.option;
        field.field_label = element.field_label;
        field.field_type = element.field_type;
      }
      field.option = options;
      fields[i] = field;
      setOnlineField(fields);
    });
  };

  const onFieldTypeChange = (e, i) => {
    let fields = [...onlineField];
    let field = { ...fields[i] };
    field.field_name = e.target.value;
    if (e.target.value === "") {
      field.field_label = "Label";
    }
    fields[i] = field;
    setOnlineField(fields);
    onlineflabel(e.target.value, i);
  };
  const onSubcategoryChange = (e, i) => {
    let fields = [...intakeField];
    let field = { ...fields[i] };
    field.field_name = e;
    // if (e.target.value === "") {
    //   field.field_label = "Label";
    // }
    fields[i] = field;
    setIntakeField(fields);
  };
  const onisReqChange = (e, i) => {
    let fields = [...onlineField];
    let field = { ...fields[i] };

    field.is_required = !field.is_required;
    fields[i] = field;
    setIntakeField(fields);
  };

  const onSubmit = (e) => {
    e.preventDefault();

    let intakeForm = [];
    if (
      !formData.book_online ||
      !formData.book_site ||
      !formData.call_to_book
    ) {
      setFormError("Please select atleast one contact method!");
    }
    if (intakeField != null && formData.book_online) {
      intakeField.map((element, index) =>
        intakeForm.push({
          field_category: element.field_category,
          field_type: "text",
          is_required: element.is_required,
          field_name: element.field_name,
          field_label: element.field_label,
          formfield: element.formfield,
          // option: element.option,
        })
      );
    }
    if (editFields != null && formData.book_online) {
      editFields.map((element, index) =>
        intakeForm.push({
          field_category: element.field_category,
          field_type: element.field_type,
          is_required: element.is_required,
          field_name: element.field_name,
          field_label: element.field_label,
          formfield: element.formfield,
          option: element.option,
        })
      );
    }

    let submitFormData = formData;
    submitFormData = { ...submitFormData, intakeForm };
    submitFormData.company_id = data._id;
    if (submitFormData._id !== null) {
      if (data.is_admin) {
        editSBusinesservice(submitFormData, history);
      } else {
        editUserServices(submitFormData, history);
      }
      setEditFields([]);
      // clearService();
      // history.goBack();
      history.push({
        pathname: `/business/service/${Company?._id}`,
        state: {
          companyDetails: Company,
          settingProp: "allbsns",
        },
      });
    } else {
      if (data.is_admin) {
        addBusinessService(submitFormData, history);
      } else {
        addUserService(submitFormData, history);
      }
      // clearService();
      history.goBack();
    }
  };

  useEffect(() => {
    let CategoryList = [{ label: "Select One", value: "" }];

    categoryList?.map((element) => {
      CategoryList.push({
        id: element._id,
        label: element.category_name,
        value: element.category_name,
      });
    });

    setCategories(CategoryList);
  }, [categoryList]);

  return (
    <>
      <Helmet>
        <title>Appointment Cake</title>
        <meta
          name="description"
          content="Whatever or whoever you’re looking for,
                   Appointment Cake has you covered."
        />
      </Helmet>
      <div
        className="card edit_service_main_container spacing"
        id="add-service"
        style={{ marginBottom: "40px" }}
      ></div>

      <div className="edit-service">
        <div className="edit_header set-align-edit-service">
          <a
            className="btn btn-blue rounded-circle mobile-top-arrow edit-service-top-arrow  d-flex justify-content-center align-items-center"
            onClick={() => history.goBack()}
          >
            <i className="fas fa-arrow-left" aria-hidden="true"></i>
          </a>
          <p className="text-2m font-bold ml-2 mb-0">Edit Service</p>
        </div>
      </div>
      <div className="registration-page edit-service-registration-page">
        <h1
          className="text-center font-bold text-dark m-0 pb-0 pb-md-4"
        >
          {name}
        </h1>
        <form
          className="form list-group edit_service_form w-75 mx-auto"
          onSubmit={(e) => onSubmit(e)}
        >
          <div className="bg-lightpeach py-20 px-40 border-20 mb-2">
            <input
              type="hidden"
              id="service-id"
              name="_id"
              onChange={(e) => onChange(e)}
              value={_id ? _id : ""}
            />
            <div className="row mb-20">
              <div className="col-sm-12">
                <input
                  className=" w-100 form-control font-medium text-medium h-43"
                  type="text"
                  name="name"
                  placeholder="Service name"
                  id="service-name"
                  required
                  value={name}
                  onChange={(e) => onChange(e)}
                />
              </div>
            </div>

            <div className="row mb-20">
              <div className="col-sm-12">
                {/* <label htmlFor="service-description">
                </label> */}
                <textarea
                  type="text"
                  className="w-100 form-control font-medium text-medium"
                  name="description"
                  placeholder="Service Description*"
                  id="service-description"
                  value={description}
                  onChange={(e) => onChange(e)}
                  required
                />
              </div>
              <div className="col-sm-12 text-muted"></div>
            </div>

            <div className="row mb-20">
              <div className="col-sm-6">
                {/* <label htmlFor="service-price"></label> */}
                <input
                  type="text"
                  className="py-10 w-100 form-control font-medium text-medium h-43"
                  name="price"
                  placeholder="Service Price"
                  id="service-price"
                  value={price}
                  required
                  // onChange={(e) => onChange(e)}
                  onChange={(e) => {
                    const inputVal = e.target.value;
                    const regex = /^[0-9]*$/;
                    if (regex.test(inputVal) || inputVal === "") {
                      onChange(e);
                    }
                  }}
                />
              </div>
              <div className="col-sm-6 mb-20">
                <select
                  className="form-control font-medium text-medium py-1 form-input text-dark drop-arrow mt-3 mt-lg-0"
                  name="service_duration"
                  placeholder="service duration in minutes"
                  id="service-service_duration"
                  value={service_duration}
                  onChange={(e) => onChange(e)}
                >
                  <option value="15">15 minutes</option>
                  <option value="30">30 minutes</option>
                  <option value="45">45 minutes</option>
                  <option value="60">60 minutes</option>
                  <option value="90">90 minutes</option>
                  <option value="120">120 minutes</option>
                </select>
              </div>
            </div>
            <>
              <p className="form-title m-0 text-left">
                How would you like to be booked?
              </p>
              <div className="flex-column gap-1 mt-3">
                <div className="contact-method-col">
                  <select
                    id="booking-method-select"
                    className="form-control drop-arrow-booking h-43"
                    name="booking_method"
                    //  value={formData.booking_method}
                    onChange={(e) => {
                      const selectedMethod = e.target.value;
                      if (selectedMethod === "call_to_book") {
                        setFormData({
                          ...formData,
                          booking_method: selectedMethod,
                          call_to_book: !formData?.call_to_book,
                          book_online: false,
                          book_site: false,
                          book_site_link: "",
                        });
                      } else if (selectedMethod === "book_site") {
                        setFormData({
                          ...formData,
                          book_site: !formData.book_site,
                          booking_method: selectedMethod,
                          book_online: false,
                          call_to_book: false,
                        });
                      } 
                      else if (selectedMethod === "use_linked_calendar") {
                        setFormData({
                          ...formData,
                          booking_method: selectedMethod,
                          book_online: true,
                          call_to_book: false,
                          book_site: false,
                          book_site_link: "",
                        });
                      }
                    }}
                  >
                    <option value="">Select Booking Method</option>
                    <option
                      value="call_to_book"
                      selected={formData.call_to_book}
                    >
                      Call to book
                    </option>
                    <option value="book_site" selected={formData.book_site}>
                      Book on another site
                    </option>
                    <option value="use_linked_calendar" selected={formData.use_linked_calendar}>
                     Use Appointment Cake calendar
        </option>
                  </select>
                </div>

                {formData.book_site && (
                  <>
                    <div className="http_bg mt-3">
                      <div className="col-sm-12 text-muted">
                        <p className="htt_disc">
                          Enter the URL you would like to redirect users to when
                          <br />
                          booking for this service.
                        </p>
                      </div>
                      <div className="col-sm-12">
                        {/* <label htmlFor="book-site-link"></label> */}
                        <input
                          type="url"
                          className="form-control http_field"
                          name="book_site_link"
                          placeholder="https://"
                          id="book-site-link"
                          required={book_site}
                          value={book_site_link ? book_site_link : ""}
                          onChange={(e) => onChange(e)}
                        />
                      </div>
                    </div>
                  </>
                )}
              </div>
            </>
            <div className="contact_method_main_container d-none">
              <div className="methode">
                <p>Booking Method*</p>
                <DetailTooltip text="Choose how you want customers to book with you!" />
                <p
                  style={{ color: "red", fontSize: "12px", marginLeft: "10px" }}
                >
                  {formError ? formError : ""}
                </p>
              </div>
            </div>

            <div className="contact-method-col d-none">
              <label className="switch">
                <input
                  type="checkbox"
                  name="typeof_booking"
                  id="service-book_online"
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      call_to_book: false,
                      book_online: !formData.book_online,
                      book_site: false,
                    });
                  }}
                  checked={book_online}
                />
                <span className="slider round"></span>
              </label>
              <label htmlFor="service-book_online">
                {" "}
                Book Through Integrated Calendar
              </label>
            </div>

            {book_online && (
              <>
                {editFields !== null
                  ? editFields.length > 0 &&
                    editFields.map((element, index) => {
                      // let filterValue = categories.filter(
                      //   (i) => i.value === element?.field_category
                      // );
                      // let filterSubValue = element.online_fieldnames.filter(
                      //   (i) => i.value === element?.field_name
                      // );
                      return (
                        <div
                          key={index}
                          className="card bookonlinemodule-wrapper"
                        >
                          <div className="card-header bg-aqua bookonline-header">
                            <div className="row">
                              <div className="col-lg-6 col-md-6 col-sm-6 mb-sm-0 mb-2">
                                {/* <Select
                                options={categories}
                                className="field-type"
                                name="field_category"
                                defaultValue={filterValue[0]}
                                onChange={(value) => {
                                  // onedit_CategoryFieldChange(value, index)
                                  let fields = [...editFields];
                                  let field = { ...fields[index] };
                                  field.selected = "";
                                  field.field_category = value.value;
                                  field.formfield = value.id;
                                  fields[index] = field;
                                  setEditFields(fields);
                                  edit_Type(value, index);
                                }}
                              /> */}
                                <input
                                  id="field_label"
                                  type="text"
                                  placeholder="Field label"
                                  name="field_label"
                                  defaultValue={element.field_label}
                                  required
                                  onChange={(e) => {
                                    var value = e.target.value.replace(
                                      " ",
                                      "_"
                                    );
                                    let fields = [...editFields];
                                    let field = { ...fields[index] };
                                    field.selected = "";
                                    field.field_name = value;
                                    field.field_label = e.target.value;
                                    field.field_type = "text";
                                    fields[index] = field;
                                    setEditFields(fields);
                                  }}
                                />
                              </div>
                              {/* <div className="col-lg-4 col-md-5 col-sm-6 mb-sm-0 mb-2  online-fields">
                              {element.online_fieldnames.length > 0 && (
                                <Select
                                  options={element.online_fieldnames}
                                  className="field-type"
                                  styles={{
                                    menu: (base) => ({
                                      ...base,
                                      zIndex: 99999999,
                                    }),
                                  }}
                                  defaultValue={
                                    filterSubValue.length > 0
                                      ? filterSubValue[0]
                                      : element.online_fieldnames[0]
                                  }
                                  value={
                                    filterSubValue.length > 0
                                      ? filterSubValue[0]
                                      : element.online_fieldnames[0]
                                  }
                                  onChange={(value) => {
                                    oneditFieldTypeChange(value.value, index);
                                  }}
                                />
                              )}
                            </div> */}
                              <div className="col-lg-5 col-md-5 col-sm-5 mt-2 mt-lg-0 col-5">
                                <div className="check-box-control">
                                  <input
                                    type="checkbox"
                                    className="form-check-input"
                                    id={index}
                                    checked={element.is_required}
                                    onClick={(e) => {
                                      onedit_isReqChange(e, index);
                                    }}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor={index}
                                  >
                                    Required ?
                                  </label>
                                </div>
                              </div>
                              <div className="col-lg-1 col-md-1 col-sm-1 mt-lg-0 mt-2 col-1">
                                <button
                                  type="button"
                                  className="btn btn-danger float-right deleteBtn edit-service-remove-btn"
                                  onClick={() => edit_deleteField(index)}
                                >
                                  -
                                </button>
                              </div>
                            </div>
                          </div>
                          <ul className="list-group list-group-flush border-bottom">
                            <li className="list-group-item online-fields">
                              <div className="row">
                                <div className="col-md-5 col-sm-12 field-label">
                                  <label className="neck_padd">
                                    {element.field_label}
                                  </label>
                                </div>

                                <div className="col-md-7 col-sm-12 set-booking-align">
                                  {element.field_type === "text" ? (
                                    <input
                                      disabled
                                      type={element.field_type}
                                      placeholder={element.field_label}
                                    ></input>
                                  ) : element.field_type === "checkbox" ? (
                                    <ul className="list_ul_custom">
                                      {element &&
                                        element.option &&
                                        element.option.map((element, index) => (
                                          <li>
                                            <div className="form-check">
                                              <label className="form-check-label lable-service">
                                                <input
                                                  disabled
                                                  id="checkbox"
                                                  type="checkbox"
                                                  className="form-check-input"
                                                  name={element.value}
                                                  value={element.value}
                                                ></input>
                                                {element.lable}
                                              </label>
                                            </div>
                                          </li>
                                        ))}
                                    </ul>
                                  ) : element.field_type === "radio" ? (
                                    element.option ? (
                                      element &&
                                      element.option &&
                                      element.option.map((element, i) => (
                                        <div className="form-check">
                                          <label className="form-check-label lable-service">
                                            <input
                                              disabled
                                              type="radio"
                                              className="form-check-input"
                                              name={index}
                                              value={element.value}
                                            ></input>
                                            {element.lable}
                                          </label>
                                        </div>
                                      ))
                                    ) : (
                                      element &&
                                      element.option &&
                                      element.option.map((element, i) => (
                                        <div className="form-check">
                                          <label className="form-check-label lable-service">
                                            <input
                                              disabled
                                              type="radio"
                                              className="form-check-input"
                                              name={index}
                                              value={element.value}
                                            ></input>
                                            {element.lable}
                                          </label>
                                        </div>
                                      ))
                                    )
                                  ) : element.field_type === "file" ? (
                                    <button
                                      disabled
                                      type="button"
                                      className="attached-comment btn btn-info"
                                    >
                                      <i className="fas fa-paperclip"></i>{" "}
                                      Upload
                                    </button>
                                  ) : element.field_type === "textarea" ? (
                                    <textarea
                                      id="bookingNotes"
                                      name="text"
                                      className="form-control"
                                      placeholder={element.field_label}
                                    ></textarea>
                                  ) : element.field_type === "slider" ? (
                                    <>
                                      <input
                                        disabled
                                        className="slider"
                                        type="range"
                                        list="tickmarks"
                                      />
                                      <datalist id="tickmarks">
                                        {element &&
                                          element.option &&
                                          element.option.map((el, i) => (
                                            <option value={i + 1}>
                                              {i + 1}
                                            </option>
                                          ))}
                                      </datalist>
                                    </>
                                  ) : element.field_type === "dropdown" ? (
                                    <select
                                      className="form-control"
                                      name="field_category"
                                      value={element.field_category}
                                      option={element.option}
                                      onChange={(e) =>
                                        onCategoryFieldChange(e, index)
                                      }
                                    ></select>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                            </li>
                          </ul>
                        </div>
                      );
                    })
                  : []}

                <div className="bookonline-button-wrapper">
                  <div className="ser_pop_create_intake_form">
                    <span>
                      Additional questions you want to ask the client:
                    </span>
                    <a onClick={(e) => addField(e)}>Add Field +</a>
                  </div>
                </div>
                {intakeField !== null
                  ? intakeField.length > 0 &&
                    intakeField.map((element, index) => {
                      let filterValue = categories.filter(
                        (i) => i.value === element?.field_category
                      );
                      return (
                        <div
                          key={index}
                          className="card bookonlinemodule-wrapper"
                        >
                          <div className="card-header bg-aqua bookonline-header">
                            <div className="row">
                              <div className="col-lg-6 col-md-6 col-sm-6 mb-sm-0 mb-2">
                                {/* <Select
                              options={categories}
                              className="field-type"
                              styles={{
                                menu: (base) => ({
                                  ...base,
                                  zIndex: 99999999,
                                }),
                              }}
                              name="category"
                              defaultValue={filterValue[0]}
                              onChange={(value) => {
                                let fields = [...intakeField];
                                let field = { ...fields[index] };
                                field.selected = "";
                                field.field_category = value.value;
                                field.formfield = value.id;
                                fields[index] = field;
                                setIntakeField(fields);
                                onCategoryChange(value, index);
                              }}
                            /> */}
                                <input
                                  className=""
                                  id="field_label"
                                  type="text"
                                  placeholder="Field label"
                                  name="field_label"
                                  required
                                  onChange={(e) => {
                                    var value = e.target.value.replace(
                                      " ",
                                      "_"
                                    );
                                    let fields = [...intakeField];
                                    let field = { ...fields[index] };
                                    field.selected = "";
                                    field.field_name = value;
                                    field.field_label = e.target.value;
                                    field.field_type = "text";
                                    fields[index] = field;
                                    setIntakeField(fields);
                                  }}
                                />
                              </div>
                              {/* <div className="col-lg-4 col-md-6 col-sm-6   online-fields">
                            {element.online_fieldnames.length > 0 && (
                              <Select
                                options={element.online_fieldnames}
                                className="field-type"
                                styles={{
                                  menu: (base) => ({
                                    ...base,
                                    zIndex: 99999999,
                                  }),
                                }}
                                defaultValue={element?.online_fieldnames[0]}
                                onChange={(value) => {
                                  onSubcategoryChange(value.value, index);
                                }}
                              />
                            )}
                          </div> */}
                              <div className="col-lg-5 col-md-5 col-sm-5 mt-2 mt-lg-0 col-4">
                                <div className="check-box-control">
                                  <label
                                    className="form-check-label requiredInput"
                                    htmlFor={index}
                                  >
                                    <input
                                      type="checkbox"
                                      className="form-check-input"
                                      id={index}
                                      onClick={(e) => {
                                        onisReqChange(e, index);
                                      }}
                                    />
                                    Required ?
                                  </label>
                                </div>
                              </div>
                              <div className="col-lg-1 col-md-1 col-sm-1 mt-lg-0 mt-2 col-6">
                                <div className="col-ms-2">
                                  <button
                                    type="button"
                                    className="btn btn-danger float-right deleteBtn"
                                    onClick={() => deleteField(index)}
                                  >
                                    -
                                  </button>
                                </div>
                              </div>
                            </div>
                            <ul className="list-group list-group-flush border-bottom padding">
                              <li className="list-group-item online-fields range_container">
                                <div className="row">
                                  <div className="col-md-5 col-sm-12 field-label px-0">
                                    <label className="lable-service">
                                      {element.field_label}
                                    </label>
                                  </div>
                                  <div className="col-md-7 col-sm-12 set-booking-align px-0 ml-1">
                                    {element.field_type === "text" ? (
                                      <input
                                        disabled
                                        type={element.field_type}
                                        placeholder={element.field_label}
                                      ></input>
                                    ) : element.field_type === "checkbox" ? (
                                      <ul>
                                        {element &&
                                          element.option &&
                                          element.option.map(
                                            (element, index) => (
                                              <li>
                                                <div className="form-check">
                                                  <label className="form-check-label lable-service">
                                                    <input
                                                      disabled
                                                      id="checkbox"
                                                      type="checkbox"
                                                      className="form-check-input"
                                                      name={element.value}
                                                      value={element.value}
                                                    ></input>
                                                    {element.lable}
                                                  </label>
                                                </div>
                                              </li>
                                            )
                                          )}
                                      </ul>
                                    ) : element.field_type === "radio" ? (
                                      element &&
                                      element.option &&
                                      element.option.map((element, i) => (
                                        <div className="form-check">
                                          <label className="form-check-label lable-service">
                                            <input
                                              disabled
                                              type="radio"
                                              className="form-check-input"
                                              name={index}
                                              value={element.value}
                                            ></input>
                                            {element.lable}
                                          </label>
                                        </div>
                                      ))
                                    ) : element.field_type === "file" ? (
                                      <button
                                        disabled
                                        type="button"
                                        className="attached-comment btn btn-info"
                                      >
                                        <i className="fas fa-paperclip"></i>{" "}
                                        Upload
                                      </button>
                                    ) : element.field_type === "textarea" ? (
                                      <textarea
                                        id="bookingNotes"
                                        name="text"
                                        className="form-control"
                                        disabled
                                        placeholder={element.field_label}
                                      ></textarea>
                                    ) : element.field_type === "slider" ? (
                                      <>
                                        <input
                                          disabled
                                          className="slider"
                                          type="range"
                                          list="tickmarks"
                                        />
                                        <datalist id="tickmarks">
                                          {element &&
                                            element.option &&
                                            element.option.map((el, i) => (
                                              <option>{i + 1}</option>
                                            ))}
                                        </datalist>
                                      </>
                                    ) : element.field_type === "dropdown" ? (
                                      <select
                                        className="form-control"
                                        name="field_category"
                                        value={element.field_category}
                                        option={element.option}
                                        onChange={(e) =>
                                          onCategoryFieldChange(e, index)
                                        }
                                      ></select>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      );
                    })
                  : []}
                {/* <div className="bookonline-button-wrapper">
              <button
                type="button"
                className="btn btn-primary float-right"
                onClick={e => addField(e)}
              >
                Add Field +
              </button>
            </div> */}
              </>
            )}
            <div className="contact-method-col d-none">
              <label className="switch">
                <input
                  type="checkbox"
                  name="typeof_booking"
                  id="service-call_to_book"
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      call_to_book: !formData.call_to_book,
                      book_online: false,
                      book_site: false,
                    });
                  }}
                  checked={formData.call_to_book}
                />
                <span className="slider round"></span>
              </label>
              <label htmlFor="service-call_to_book"> Call to book</label>
            </div>
            {/* <div className="list-group-item"> */}

            {/* </div> */}
            <div className="contact-method-col d-none">
              <label className="switch">
                <input
                  type="checkbox"
                  name="typeof_booking"
                  id="service-book_site"
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      call_to_book: false,
                      book_online: false,
                      book_site: !formData.book_site,
                      book_site_link: e.target.checked
                        ? formData.book_site_link
                        : "",
                    });
                  }}
                  checked={book_site}
                />
                <span className="slider round"></span>
              </label>
              <label htmlFor="service-book_site">
                {" "}
                Bookable From Other Site{" "}
              </label>
            </div>
            <div className="row d-none">
              {formData.book_site && (
                <>
                  <div className="http_bg">
                    <div className="col-sm-12 text-muted">
                      <p className="htt_disc">
                        Enter the URL you would like to redirect users to when
                        <br />
                        <span> </span>
                        booking for this service.
                      </p>
                    </div>
                    <div className="col-sm-12">
                      {/* <label htmlFor="book-site-link"></label> */}
                      <input
                        type="text"
                        className="form-control http_field"
                        name="book_site_link"
                        placeholder="https://"
                        id="book-site-link"
                        // required={book_site}
                        value={book_site_link ? book_site_link : ""}
                        onChange={(e) => onChange(e)}
                      />
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
          <button
            type="submit"
            className="btn btn-primary w-50 mx-auto  font-semibold mt-1 text-xs"
          >
            Save Changes
          </button>
        </form>
      </div>
    </>
  );
};

EditUserService.propTypes = {
  // getAllPlans: PropTypes.func.isRequired
  // auth: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  company: state.company.companylist,
  totalAdminBusiness: state.company,
  formfields: state.formfields,
  categoryList: state.company.category,
});

export default connect(mapStateToProps, {
  addBusinessService,
  editSBusinesservice,
  editUserServices,
  addUserService,
})(EditUserService);
